<template>
  <div>
    <template v-if="!color">
      <div class="color rounded no-color" :style="{'--size': size}" ></div>
    </template>
    <template v-else>
      <div class="color rounded elevation-0" :style="{backgroundColor: handledColor, '--size': size}" :class="[{'elevation-4': hover}]"></div>
    </template>
  </div>
</template>

<script>
import colors from 'vuetify/lib/util/colors'

export default {
  name: "Color",
  props: {
    color: String,
    xSmall: Boolean,
    small: Boolean,
    large: Boolean,
    xLarge: Boolean,
  },
  computed: {
    handledColor() {
      if (this.color) {
        if (this.color.includes("#")) {
          return this.color
        } else {
          return (colors[this.color]||[]).base || this.$vuetify.theme.themes.light[this.color]
        }
      } else {
        return null
      }
    },
    size() {
      return this.xSmall
          ? '8px'
          : this.small
              ? '12px'
              : this.large
                  ? '20px'
                  : this.xLarge
                      ? '24px'
                      : "16px"
    }
  },
  data() {
    return {
      hover: true
    }
  }
}
</script>

<style scoped>


.color {
  --size: 0;
  content: "";
  width: var(--size); height: var(--size);
  max-width: var(--size); max-height: var(--size);
  margin: 4px;
  cursor: pointer;
}

.color.no-color {
  border: 1px solid black;
  /*background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M12.7771 1.10161L1.10161 12.777C1.37768 13.8094 2.19061 14.6223 3.22294 14.8984L14.8984 3.22295C14.6223 2.19062 13.8094 1.37768 12.7771 1.10161Z' fill='%23FF0000'/%3e%3c/svg%3e ");*/
  /*background-size: cover;*/
  /*background-position: -1px -1px;*/
  background: linear-gradient(to top left,
  rgba(255,0,0,0) 0%,
  rgba(255,0,0,0) calc(50% - 2px),
  rgba(255,0,0,1) calc(50% - 2px),
  rgba(255,0,0,1) calc(50% + 2px),
  rgba(255,0,0,0) calc(50% + 2px),
  rgba(255,0,0,0) 100%);
}
</style>
